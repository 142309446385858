import { supabase } from "@/lib/supabase";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { ResponseWithMetadata } from "@/types/response";
import { useEditorStore } from "@/store/editor.store";
import { ResponsesDataTable } from "@/routes/studio/editor/responses/ResponsesDataTable";
import { ResponsesColumns } from "./ResponseColumns";
import {
  listResponses,
  ResponsesFilter,
} from "@/lib/data/studio/response.service";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { toast } from "sonner";
import { exportResponses } from "@/lib/excel";
import { forceEndSession } from "@/lib/data/session.service";
import { Input } from "@/components/ui/input";
import { DateFilter } from "./DateFilter";
import { DownloadIcon } from "@radix-ui/react-icons";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { Button } from "@/components/ui/button";

export default function QuizResponses() {
  const { quizId } = useParams<{ quizId: string }>();
  const { quiz } = useEditorStore();
  const workspaceId = useCurrentWorkspaceId();
  const [responses, setResponses] = useState<ResponseWithMetadata[]>([]);
  const query = useRef<ResponsesFilter>({
    quizId: quizId,
    userName: undefined,
    date: undefined,
  })

  useEffect(() => {
    if (!quizId || !workspaceId) {
      return;
    }

    const load = async () => {
      const { data } = await supabase
        .from("responses")
        .select("*,profile:profiles(*),audience(*, group:audience_groups(*)),quiz:quizs(id,workspaceId)")
        .not('quiz', 'is', null)
        .eq("quizId", quizId)
        .eq("quiz.workspaceId", workspaceId)
        .order("isCompleted", { ascending: false })
        .returns<ResponseWithMetadata[]>();
      if (data) {
        setResponses(data);
      }
    };

    load();
  }, [quizId, workspaceId]);

  const fetchResponses = async (filter: ResponsesFilter) => {
    setResponses([])
    const data = await listResponses({
      workspaceId: workspaceId!,
      quizId: quizId,
      userName: filter?.userName,
      date: filter?.date,
    });
    setResponses(data);
  };

  const handleExport = async (customResponses?: ResponseWithMetadata[]) => {
    if (!quiz) {
      return;
    }
    toast.info("Your download will start shortly");
    const file = await exportResponses(quiz, customResponses || responses);
    const blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${quiz.title}-responses-QuizBase.xlsx`;
    a.click();
  }

  const handleExportAllGroups = async () => {
    const q = quiz!;

    let query = supabase.from('audience')
      .select("id,code,name,profile:profiles(id,email),responses(id,score,maxScore,isCompleted,duration,createdAt,state,reason)")
      .eq('workspaceId', workspaceId!)
      .eq('responses.quizId', quizId!)

    // get all students from allowedGroups
    if (q.config.allowedGroups?.length) {
      query = query.in('groupId', q.config.allowedGroups)
    }

    const { data, error } = await query.order('code', { ascending: true })

    if (error) {
      toast.error(error.message);
      return;
    }

    // convert result to ResponseWithMetaData[]
    const result: any[] = data.map((item) => ({
      profile: item.profile,
      audience: {
        id: item.id,
        name: item.name,
        code: item.code,
      },
      ...(item.responses.length ? item.responses[0] : {}),
    }));

    handleExport(result);
  }

  const handleExportStudentsWithNoResponses = async () => {
    const q = quiz!;

    let query = supabase.from('audience')
      .select("id,code,name,profile:profiles(id,email),responses(id)")
      .eq('workspaceId', workspaceId!)
      .is('responses', null)

    // get all students from allowedGroups
    if (q.config.allowedGroups?.length) {
      query = query.in('groupId', q.config.allowedGroups)
    }

    const { data, error } = await query.order('code', { ascending: true })

    if (error) {
      toast.error(error.message);
      return;
    }

    // convert result to ResponseWithMetaData[]
    const result: any[] = data.map((item) => ({
      profile: item.profile,
      audience: {
        id: item.id,
        name: item.name,
        code: item.code,
      },
      ...(item.responses.length ? item.responses[0] : {}),
    }));

    handleExport(result);
  }

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const form = new FormData(e.target as HTMLFormElement);
    const text = form.get("text") as string;
    const date = form.has('date') ? new Date(form.get("date") as string) : undefined;
    query.current = {
      quizId: quiz?.id,
      userName: text,
      date: date,
    };
    fetchResponses(query.current);
  }

  const onEndSession = async (responseId: string) => {
    // end session
    await forceEndSession(responseId, "completed", "ended");
    fetchResponses(query.current)
  }

  const onDeleteAnswer = async (responseId: string) => {
    await supabase.from("responses").delete().eq("id", responseId);
    fetchResponses(query.current);
  };

  const handleAction = (action: string) => {
    switch (action) {
      case 'export':
        handleExport();
        break;
      case 'exportAll':
        handleExportAllGroups();
        break;
      case 'exportStudentsWithNoResponses':
        handleExportStudentsWithNoResponses();
        break;
    }
  }

  return (
    <div>
      <ResponsesFilters onSearch={handleSearch} onAction={handleAction} />
      <ResponsesDataTable
        columns={ResponsesColumns()}
        data={responses}
        quiz={quiz}
        onDeleteAnswer={onDeleteAnswer}
        onEndSession={onEndSession}
      />
    </div>
  )

  // return (
  //   <div className="">
  //     <ResponsesFilters onSearch={handleSearch} onAction={handleAction} />
  //     <MobileListView responses={responses} />
  //   </div>
  // )

}


// FIXME: the data table doesn't look good on small screens, a different view would make sense
// or maybe different columns design for the data table would be sufficient
// const MobileListView = ({ responses }: { responses: ResponseWithMetadata[] }) => {
//   return (
//     <div className="flex flex-col gap-1">
//       {responses.map((response) => (
//         <div key={response.id} className="flex gap-2 bg-white border-y p-4">
//           <UserAvatar name={response.audience.name} url={response.profile?.avatar_url} />
//           <div className="">
//             <p className="font-bold">{response.audience.name}</p>
//             <span className="text-muted-foreground">{response.audience?.code}</span>
//             {/* <span className="text-muted-foreground">{format(response.createdAt, "dd/MM/yyyy")}</span> */}
//           </div>
//           <span className="flex-1" />
//           <div className="flex items-center gap-2">
//             <p className="font-bold">{response.score} <span className="text-muted-foreground">/{response.maxScore}</span></p>
//           </div>
//         </div>
//       ))}
//     </div>
//   )
// }

const ResponsesFilters = ({ onSearch, onAction }: { onSearch: (e: React.FormEvent) => void, onAction: (action: string) => void }) => {
  const textFilter = useRef<string | undefined>(undefined);
  const dateFilter = useRef<Date | undefined>(undefined);
  const form = useRef<HTMLFormElement>(null)


  const handleTextFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    textFilter.current = e.target.value;
  }

  const handleDateFilterOnChange = (d: Date | undefined) => {
    dateFilter.current = d;
    form.current?.submit();
  };

  return (
    <form ref={form} onSubmit={onSearch} className="flex flex-row items-center gap-4 py-3 px-3">
      <Input
        placeholder="Filter users ..."
        onChange={handleTextFilterChange}
        className="max-w-1/4 lg:min-w-[140px] bg-white"
      />
      <DateFilter onChange={handleDateFilterOnChange} />
      <span className="flex-1" />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button>Export Results <DownloadIcon /></Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => onAction('export')}><DownloadIcon /> Responses only</DropdownMenuItem>
          <DropdownMenuItem onClick={() => onAction('exportAll')}><DownloadIcon /> All students</DropdownMenuItem>
          <DropdownMenuItem onClick={() => onAction('exportStudentsWithNoResponses')}><DownloadIcon /> Students who didn't take the quiz</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </form>
  )
}