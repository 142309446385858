import WorkspaceInfoCard from "@/components/WorkspaceInfoCard";

export default function SessionLayout({ workspace, children, topRight }: { workspace: {id: string, name: string, description: string | null, logo: string | null}, topRight?: React.ReactNode, children: React.ReactNode }) {
    return (
        <div className="min-h-screen bg-white p-8">
            <div className="mx-auto flex max-w-3xl flex-col gap-8">

                <div className="flex justify-between items-center">
                    <WorkspaceInfoCard
                        id={workspace.id}
                        name={workspace.name}
                        description={workspace.description}
                        logo={workspace.logo}
                    />
                    {topRight}
                </div>

                {children}
            </div>
        </div>
    )
}