import SwitchBox from "@/components/SwitchBox";
import { Separator } from "@/components/ui/separator";
import { QuizConfig } from "@/types/quiz";
import { supabase } from "@/lib/supabase";
import { useEditorStore } from "@/store/editor.store";
import {
  EnterIcon,
  ExclamationTriangleIcon,
  ShuffleIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import QuizResultsSettings from "./QuizResultsSettings";
import QuizAccessSettings from "./UniversityQuizAccessSettings";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { useNavigate } from "react-router";
import { AlertDialogAction } from "@radix-ui/react-alert-dialog";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { useCurrentWorkspace } from "@/hooks/use-current-ws";
import CompanyQuizAccessSettings from "./CompanyQuizAccessSettings";

export default function QuizSettings() {
  const navigate = useNavigate();
  const workspace = useCurrentWorkspace();
  const { quiz, setQuiz } = useEditorStore();


  if (!quiz) {
    return;
  }

  const onChange = async (config: QuizConfig) => {
    setQuiz({ ...quiz, config: { ...quiz.config, ...config } });
    // save to supabase db
    await supabase
      .from("quizs")
      .update({ config: { ...config } })
      .eq("id", quiz.id);
  };

  const handleRandomizeQuestions = async (v: boolean) => {
    onChange({ ...quiz.config, shuffleQuestions: v });
  };

  const handleRandomizeChoices = async (v: boolean) => {
    onChange({ ...quiz.config, shuffleChoices: v });
  };

  // const handleQuizModeClicked = (v: "focused" | "fluid") => {
  //   onChange({ ...quiz.config, mode: v });
  // };

  const handleDeleteQuiz = async () => {
    const { error } = await supabase.from("quizs").delete().eq("id", quiz.id);
    if (error) {
      console.error(error);
      if (error.code === "23503") {
        toast.error("Can't delete quiz while it contains responses.", {
          description: "If you want to delete the quiz, delete all the responses first and try again.",
          dismissible: true
        });
      } else {
        toast.error("Failed to delete quiz, please contact support", {
          description: error.message,
          dismissible: true
        });
      }
      return;
    }
    navigate("/studio/quizzes");
  };

  const handleMaxAttemptsChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.target.value) {
      return;
    }

    const maxAttempts = parseInt(e.target.value);
    if (isNaN(maxAttempts)) {
      toast.error("Please enter a valid number.");
      return;
    }

    setQuiz({
      ...quiz,
      config: {
        ...quiz.config,
        maxAttempts,
      },
    });

    // save to supabase db
    await supabase
      .from("quizs")
      .update({ config: { ...quiz.config, maxAttempts } })
      .eq("id", quiz.id);

    toast.info(`Max attempts set to ${maxAttempts}.`);
  };

  return (
    <section className="flex flex-1 flex-col gap-4 px-4 md:max-w-2xl lg:px-8">
      
      {workspace?.persona.name === "university" && <QuizAccessSettings />}
      {workspace?.persona.name !== "university" && <CompanyQuizAccessSettings />}

      <Separator />

      {/* <div className="flex items-start gap-3">
        <div className="">
          <p className="flex items-center gap-2 leading-7 font-semibold">
            <PlayIcon /> Quiz mode
          </p>
          <p className="text-muted-foreground px-6 text-xs">
            Control how users interact with the quiz
          </p>
        </div>
        <span className="flex-1" />
        <QuizFocusedToggleItem
          isActive={quiz.config.mode === "focused"}
          onClick={() => handleQuizModeClicked("focused")}
        />
        <QuizFluidToggleItem
          isActive={quiz.config.mode === "fluid"}
          onClick={() => handleQuizModeClicked("fluid")}
        />
      </div> */}

      {/* <Separator /> */}

      <section>
        <h4 className="flex items-center gap-2 leading-7 font-semibold">
          <EnterIcon /> Max Attempts
        </h4>
        <p className="text-muted-foreground px-6 text-sm">
          Control how many times can a user attempt the quiz. Set to 0 for
          infinite attempts.
        </p>

        <div className="mt-4 flex flex-col px-4">
          <Input
            className="bg-white"
            placeholder="Set to 0 for infinite attempts."
            defaultValue={quiz.config.maxAttempts ?? 0}
            onChange={handleMaxAttemptsChange}
          />
        </div>
      </section>

      <div>
        <div className="inline-flex items-center gap-2">
          <ShuffleIcon />
          <h4 className="text-lg leading-loose font-semibold">
            Shuffling & Randomness
          </h4>
        </div>
        <p className="px-6 text-sm text-neutral-600">
          Configure the behavior of shuffling questions & choices.
        </p>
      </div>

      <div className="px-4">
        <SwitchBox
          label="Shuffle questions"
          description="Randomize the order of questions"
          checked={quiz.config.shuffleQuestions}
          onChange={handleRandomizeQuestions}
        />
      </div>
      <div className="px-4">
        <SwitchBox
          label="Shuffle choices"
          description="Randomize the order of answers in each question"
          checked={quiz.config.shuffleChoices}
          onChange={handleRandomizeChoices}
        />
      </div>

      <Separator />

      <QuizResultsSettings />

      <Separator />

      <DangerSettingsArea
        quizTitle={quiz.title}
        onDeleteQuiz={handleDeleteQuiz}
      />
    </section>
  );
}

// const QuizFocusedToggleItem = ({
//   isActive,
//   onClick,
// }: {
//   isActive: boolean;
//   onClick: () => void;
// }) => {
//   return (
//     <CustomToggleItem
//       title={"Focused"}
//       description="One question at a time, no going back."
//       isActive={isActive}
//       onClick={onClick}
//     >
//       <Skeleton className="h-3 w-full" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <div className="mt-4 flex items-center justify-end gap-2 text-xs">
//         <Skeleton className="mt-2 ml-2 h-3 w-12" />
//       </div>
//     </CustomToggleItem>
//   );
// };

// const QuizFluidToggleItem = ({
//   isActive,
//   onClick,
// }: {
//   isActive: boolean;
//   onClick: () => void;
// }) => {
//   return (
//     <CustomToggleItem
//       title={"Fluid"}
//       description="All the questions in one page"
//       isActive={isActive}
//       onClick={onClick}
//     >
//       <Skeleton className="h-3 w-full" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//       <Skeleton className="mt-3 h-3 w-full" />
//       <Skeleton className="mt-1.5 ml-2 h-2 w-16" />
//     </CustomToggleItem>
//   );
// };

export const DangerSettingsArea = ({
  quizTitle,
  onDeleteQuiz,
}: {
  quizTitle: string;
  onDeleteQuiz: () => void;
}) => {
  return (
    <div className="rounded border border-red-800 bg-red-50 p-4">
      <h3 className="text-xl font-semibold text-red-800">Danger Zone</h3>
      <p className="inline-flex items-center gap-4 py-4">
        <ExclamationTriangleIcon className="h-6 w-6 text-red-700" />
        <span className="text-sm text-gray-700">
          This area contains actions that are irreversible and may cause data
          loss, please be careful.
        </span>
      </p>

      <Separator />

      <div className="flex items-center gap-2 py-6">
        <div>
          <h4 className="leading-6">Delete quiz</h4>
          <p className="text-muted-foreground text-xs">
            Delete the quiz with all associated responses.
          </p>
        </div>

        <span className="flex-1" />
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant={"destructive"}>
              <TrashIcon />
              Delete quiz
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Delete <b>{quizTitle}</b>?
              </AlertDialogTitle>
              <AlertDialogDescription>
                Are you absolutely sure you want to delete the quiz? This action
                cannot be undone, all responses associated with this quiz will
                be lost.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction asChild>
                <Button variant="destructive" onClick={onDeleteQuiz}>
                  Delete
                </Button>
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};
