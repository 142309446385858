import { useEditorStore } from "@/store/editor.store";
import { CalendarIcon, ClockIcon, PersonIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Link, useParams } from "react-router";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { toast } from "sonner";
import QuizModal from "@/studio/QuizModal";
import { useDialogStore } from "@/store/dialog.store";

export default function QuizOverview() {
    const { quiz } = useEditorStore()
    const workspaceId = useCurrentWorkspaceId()
    const { quizId } = useParams();
    const [studentCount, setStudentCount] = useState(0)
    const { addDialog } = useDialogStore()

    // get total number of students who can take the quiz
    useEffect(() => {
        if (!workspaceId) return;
        if (!quiz) return;
    
        const fetchAudienceGroups = async () => {
            const query = supabase.from("audience").select("count").eq("workspaceId", workspaceId!)
            if (quiz?.config.allowedGroups?.length) {
                query.in("groupId", quiz?.config.allowedGroups)
            }
            const { data, error } = await query;
            if (error) {
                console.error(error);
                toast.error("Failed to fetch student groups", {
                    description: error.message,
                    dismissible: true
                });
                return
            }
            setStudentCount(data[0].count)
        }


        fetchAudienceGroups();
    }, [quiz?.id, quiz?.config.allowedGroups])

    if (!quiz) {
        return <div>loading...</div>
    }

    const openEditQuizModal = () => {
        const elm = React.createElement(QuizModal, {
            mode: 'edit',
            quiz: quiz,
        })
        addDialog(elm)
    }

    // Calculate statistics
    const totalQuestions = quiz.questionsCount[0].count || 0;
    const totalResponses = quiz.responses[0].count;
    const completionRate = totalResponses / studentCount * 100;
    const startTime = quiz.config.timeWindow?.start ? format(new Date(quiz.config.timeWindow?.start), "dd LLL yyyy") : "";

    return (
        <section className="max-w-4xl flex-1 px-4 lg:px-8 py-6">
            <div className="grid gap-6">
                {/* Statistics Cards */}
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
                    <Card className="shadow-none">
                        <CardHeader className="flex flex-row items-center justify-between pb-2">
                            <CardTitle className="text-sm font-medium">Total Questions</CardTitle>
                            <QuestionMarkCircledIcon className="h-4 w-4 text-gray-500" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{totalQuestions}</div>
                            <p className="text-xs text-gray-500">
                                <Link to={`/studio/quiz/${quizId}/questions`} className="text-blue-600 hover:underline">
                                    Manage questions
                                </Link>
                            </p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between pb-2">
                            <CardTitle className="text-sm font-medium">Total Responses</CardTitle>
                            <PersonIcon className="h-4 w-4 text-gray-500" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{totalResponses}</div>
                            <p className="text-xs text-gray-500">
                                <Link to={`/studio/quiz/${quizId}/responses`} className="text-blue-600 hover:underline">
                                    View all responses
                                </Link>
                            </p>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between pb-2">
                            <CardTitle className="text-sm font-medium">Completion Rate</CardTitle>
                            <ClockIcon className="h-4 w-4 text-gray-500" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{completionRate.toFixed(2)}%</div>
                            <Progress value={completionRate} className="h-2" />
                        </CardContent>
                    </Card>

                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between pb-2">
                            <CardTitle className="text-sm font-medium">Quiz Time</CardTitle>
                            <CalendarIcon className="h-4 w-4 text-gray-500" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-lg font-bold">{startTime}</div>
                            <p className="text-xs text-gray-500">
                                {quiz.config.timeWindow?.duration ? `${quiz.config.timeWindow.duration} minutes` : ""}
                            </p>
                        </CardContent>
                    </Card>
                </div>

                {/* Quiz Details Card */}
                <Card>
                    <CardHeader>
                        <CardTitle className="text-muted-foreground">Quiz Details</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-xs uppercase text-muted-foreground">Title</h3>
                                <p className="text-gray-800 text-lg">{quiz.title}</p>
                            </div>

                            <div>
                                <h3 className="text-xs uppercase text-muted-foreground">Description</h3>
                                <p className="text-gray-800">{quiz.description || "No description provided"}</p>
                            </div>

                            <div className="flex flex-wrap gap-4 pt-2">

                                <Button onClick={openEditQuizModal}>Edit Quiz</Button>

                                <Button variant="link" size="sm" asChild>
                                    <Link
                                        to={`/studio/quiz/${quizId}/questions`}
                                        className="flex items-center gap-2 text-sm"
                                    >
                                        Edit Questions
                                    </Link>
                                </Button>


                                <Button variant="link" size="sm" asChild>
                                    <Link
                                        to={`/studio/quiz/${quizId}/settings`}
                                        className="flex items-center gap-2 text-sm"
                                    >
                                        Edit Settings
                                    </Link>
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </section>
    );
}
